<template>
    <!-- <div class="row">
        <div class="col-12">

            <div class="accordion" id="filterAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters" aria-expanded="false" aria-controls="filters">
                            {{$t('events.filters.title')}} ({{$t('events.filters.click_to_open')}})
                        </button>
                    </h2>
                    <div id="filters" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#filterAccordion">
                        <div class="accordion-body">
                            <Form @submit="(values) => loadBlacklist(pagination.page, values)" :validation-schema="schema">
                                <div class="row">
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="vehicleRegistrationFilter" class="form-label">{{$t('events.filters.vehicle_registration')}}</label>
                                        <Field type="text" name="registration" class="form-control" id="vehicleRegistrationFilter" />
                                        <ErrorMessage name="registration" class="error-feedback" />
                                    </div>
                                </div>
                                <div class="row m-t-md">
                                    <div class="col-12" style="width:auto;">
                                        <button type="submit" :disabled="loading" class="btn btn-primary" style="max-width: 150px;">
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>{{ $t('events.filters.search') }}</span>
                                        </button>
                                    </div>

                                  
                                    <div class="col-12" style="width:auto;">
                                        <button type="reset" :disabled="loading" class="btn btn-primary" style="max-width: 150px;" @click="clearFilter">
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>{{ $t('global.clear_filter') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="row m-t-xs">
        <div class="col-12">

            <div class="card widget widget-list">

                <div class="section-description">
                    <h1>{{ $t('global.blacklist') }}</h1>
                </div>
                <div class="card-body" style="overflow-x: hidden">
                    <div v-show="loading" style="width: 100%; text-align: center;">
                        <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
                            <span class="visually-hidden">{{ $t('global.loading') }}...</span>
                        </div>
                    </div>

                    <pagination v-show="!loading" :config="pagination" @search="(page) => loadBlacklist(page, currentParams)"/> 
                    <table v-show="!loading" class="table table-striped m-t-lg m-b-lg">
                        <thead>
                            <tr>
                                <th scope="col">{{ $t('events.filters.vehicle_registration') }}</th>
                                <th scope="col">{{ $t('global.make') }}</th>
                                <th scope="col">{{ $t('global.model') }}</th>
                                <th scope="col">{{ $t('global.reason') }}</th>
                                <th scope="col">{{ $t('global.action') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="bl in blacklist" :key="'blacklist-' + bl.vehicle_id" style="vertical-align: middle;">
                                <td>
                                    <span class="vrm bold number-plate-gbr">{{bl.vehicle.plate}}</span>
                                </td>
                                <td>{{!!bl.vehicle.make ? bl.vehicle.make : 'N/A'}}</td>
                                <td>{{!!bl.vehicle.model ? bl.vehicle.model : 'N/A'}}</td>
                                <td>{{ $t('event.' + bl.reason.split(' ').join('_').toLowerCase()) }}</td>
                                <td><button class="btn btn-danger btn-style-light flex-grow-1 m-1-xxs" @click="removeBlacklist(bl.vehicle.plate)"><i class="material-icons">close</i>{{ $t('global.remove') }}</button></td>
                            </tr>
                        </tbody>
                    </table>

                    <pagination v-show="!loading" :config="pagination" @search="(page) => loadBlacklist(page, currentParams)"/>
                </div>
            </div>

        </div>
    </div> -->
    <div class="row">
        <div class="col-12">
            <!-- Filter -->
            <div class="accordion" id="filterAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters" aria-expanded="false" aria-controls="filters">
                            {{$t('events.filters.title')}} ({{$t('events.filters.click_to_open')}})
                        </button>
                    </h2>
                    <div id="filters" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#filterAccordion">
                        <div class="accordion-body">
                            <Form @submit="(values) => loadBlacklist(pagination.page, values)" :validation-schema="schema" ref="form">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="vehicleRegistrationFilter" class="form-label">{{$t('events.filters.vehicle_registration')}}</label>
                                        <Field type="text" name="registration" class="form-control" id="vehicleRegistrationFilter" placeholder="Enter Registration"/>
                                        <ErrorMessage name="registration" class="error-feedback" />
                                    </div>  
                                    <!-- <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="startDateFilter" class="form-label"> Start Date </label>
                                            <Field type="date" name="startDate" class="form-control" id="startDateFilter" placeholder="Select start date"> </Field>
                                            <ErrorMessage name="startDate" class="error-feedback"/>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="endDateFilter" class="form-label"> End Date </label>
                                            <Field type="date" name="endDate" class="form-control" id="endDateFilter"> </Field>
                                            <ErrorMessage name="endDate" class="error-feedback"/>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="claimTypeFilter" class="form-label"> {{ $t('reports.claim_type') }} </label>
                                        <Field type="text" name="claimType" class="form-select" id="claimTypeFilter" as="select"> 
                                            <option value="" selected> {{$t('evidences.select_a_claim_type')}} </option>
                                            <option value="driveoff"> {{$t('global.driveoff')}} </option>
                                            <option value="nmop"> {{$t('global.nmop')}}</option>
                                            <option value="parking"> {{$t('global.parking')}} </option>
                                        </Field>
                                        <ErrorMessage name="claimType" class="error-feedback"/>
                                    </div> -->
                                </div>

                                <div class="row m-t-md">
                                    <div class="col-12" style="width:auto;">
                                        <button type="submit" :disabled="loading" class="btn btn-primary" style="max-width: 150px;">
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>{{ $t('events.filters.search') }}</span>
                                        </button>
                                    </div>

                                    <div class="col-12" style="width:auto;">
                                        <button type="reset" :disabled="loading" class="btn btn-secondary" style="max-width: 150px;" @click="(values) => clearFilter(pagination.page, values)">
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>{{ $t('global.clear_filter') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Table Heading -->
            <h1 class="table-heading m-b-xs"> {{ $t('global.blacklist') }} </h1>

            <!-- Pagination -->
            <Pagination v-show="!loading" :config="pagination" @search="(page) => loadBlacklist(page, currentParams)"/>

            <!-- Loading -->
            <div v-show="loading" style="width: 100%; text-align: center;">
                <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
                    <span class="visually-hidden">{{ $t('events.loading') }}...</span>
                </div>
            </div>

            <!-- Blacklist -->
            <table v-show="!loading" class="table table-borderless">
                <thead>
                    <tr>
                        <th scope="col"> {{ $t('events.filters.vehicle_registration').toUpperCase() }} </th>
                        <th scope="col"> {{ $t('global.make').toUpperCase() }} </th>
                        <th scope="col"> {{ $t('global.fuelType').toUpperCase() }}</th>
                        <th scope="col"> {{ $t('global.reason').toUpperCase() }} </th>
                        <th scope="col"> {{ $t('global.blacklisted_at').toUpperCase() }} </th>
                        <th scope="col"> {{ $t('global.action').toUpperCase() }} </th>
                    </tr>
                </thead>
                <tbody style="vertical-align: middle;">
                    <tr v-show="blacklist.length < 1">
                        <td colspan="5" class="no-data-container"> {{ $t('global.no_data') }} </td>
                    </tr>
                    <!-- Table Content -->
                    <tr v-for="elem in blacklist" :key="'blacklist-' + elem.vehicle_id" class="cur_pointer" @click="$router.push({path: `/vehicle/${elem.vehicle.plate}`, params: {data: elem}})">
                        <td>
                           <span class="vrm bold number-plate-gbr"> {{elem.vehicle.plate}} </span> 
                        </td>
                        <td> {{ elem.vehicle.make }} </td>
                        <td> {{ elem.vehicle.fuelType }} </td>
                        <td> {{ elem.reason }} </td>
                        <td> {{ elem.blacklisted_at }} </td>
                        <td @click.stop="openModal(elem.vehicle)"> 
                            <button class="btn bg-danger" id="add-bl-btn"> 
                                <!-- <div v-if="removingVehicle" class="spinner-border" role="status" style="margin-right: 5px">
                                    <span class="visually-hidden">{{ $t('global.loading') }}...</span>
                                </div> -->
                                <i v-if="!loading" class="material-icons" :class="loading ? 'display-none' : '' "> close </i> 
                                {{ $t('global.remove') }}
                            </button> 
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- Pagination -->
            <Pagination v-show="!loading" :config="pagination" @search="(page) => loadBlacklist(page, currentParams)"/>

             <!-- Remove from Blacklist Modal -->
            <div class="modal fade" id="unBlacklistModal" tabindex="-1" aria-labelledby="successModalTitle" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content p-h-xxl p-v-xxl">
                        <div class="modal-title flex-center">
                            <h3 class="m-r-sm"> {{$t('blacklist.are_you_sure').toUpperCase()}} </h3>
                            <i class="material-icons"> error_outline </i>
                        </div>
                        <p class="p-v-sm"> {{$t('blacklist.modal_message')}} </p>
                        <div class="flex_center">
                            <button class="btn btn-secondary m-r-md" @click="() => unBlacklistModal.hide()"> {{$t('global.cancel')}} </button>
                            <button class="btn btn-primary" @click="unblacklist"> {{$t('blacklist.remove_it')}} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import BlacklistService from '../../services/blacklist.service';
// import {Form, Field, ErrorMessage} from 'vee-validate';
// import * as yup from 'yup';
// import Pagination from '../../components/Pagination.vue';
// export default {
//   components: { Form, Field, ErrorMessage, Pagination },
//     name: 'Blacklist',
//     data(){
//         const schema = yup.object().shape({
//             registration: yup.string().min(3, 'Must be at least 3 characters!').max(12, 'Must be no more than 12 characters!'),
//         });
//         return {
//             loading: true,
//             blacklist: [],
//             pagination: {
//                 page: 0,
//                 showing: 0,
//                 total: 0,
//                 lastPage: 0,
//                 maxPage: 0
//             },
//             schema: schema,
//             currentParams: {},
//         }
//     },
//     mounted(){
//         this.loadBlacklist();
//     },
//     methods: {
//         loadBlacklist(page = 1, params = {}) {
//             this.loading = true;
//             this.currentParams = params;
//             BlacklistService.getBlacklist(page, this.$store.state.auth.user.selectedClient, params).then((response) => {
//                 this.pagination = response.data.pagination;
//                 this.blacklist = response.data.blacklist;
//                 this.loading = false; 
//             });
//         },
//         removeBlacklist(registration) {
//             BlacklistService.removeBlacklist(this.$store.state.auth.user.selectedClient, registration).then((response) => {
//                 this.blacklist = this.blacklist.filter((el) => {
//                     return el.vehicle.plate !== registration;
//                 });
//             });
//         },
//         clearFilter() {
//             loadBlacklist(page = 1, params= {});
//         }
//     }
// }

//Components 
import {Form, Field, ErrorMessage } from 'vee-validate';
import Pagination from '../../components/Pagination.vue';

// Dependencies
import * as yup from 'yup';
import BlacklistService from '../../services/blacklist.service';
import { DateTime } from 'luxon';


export default {
    name: 'Blacklist',
    components: { Form, Field, ErrorMessage, Pagination },
    data() {
        const schema = yup.object().shape({
            registration: yup.string().min(3, 'Must be at least 3 characters!').max(12, 'Must be no longer than 12 characters!'),
            claimType: yup.string()
        });
        return {
            schema,
            blacklist: [],
            pagination: {
                page: 0,
                showing: 0,
                total: 0,
                lastPage: 0,
                maxPage: 0
            },
            loading: true,
            currentParams: {},
            unBlacklistModal: null,
            selectedClient: this.$store.state.auth.user.selectedClient,
            removingVehicle: false,
            selectedVehicle: null
        }
    },
    mounted() {
        this.loadBlacklist();
        this.unBlacklistModal = new bootstrap.Modal(document.getElementById('unBlacklistModal'));
    },
    methods: {
        loadBlacklist(page = 1, params = {}) {
            this.loading = true; 
            this.currentParams = params;
            if(this.$route.params) {
                this.currentParams.start_date = this.$route.params.start_date;
                this.currentParams.end_date = this.$route.params.end_date; 
            }
            BlacklistService.getBlacklist(page, this.$store.state.auth.user.selectedClient, this.currentParams)
            .then(response => {
                response.data.blacklist.forEach((entry) => {
                    entry.blacklisted_at = DateTime.fromSeconds(entry.created_at).toLocaleString(DateTime.DATETIME_MED);
                })
                this.blacklist = response.data.blacklist;
                this.pagination = response.data.pagination;
                this.loading = false;
            })
            .catch(error => {
                this.$error(this.$t('blacklist.load_blacklist_error'), error);
                this.loading = false;
            })
        },
        unblacklist() {
            this.removingVehicle = true; 
            BlacklistService.removeFromBlacklist(this.$store.state.auth.user.selectedClient, this.selectedVehicle.plate)
            .then(response => {
                this.blacklist = this.blacklist.filter(elem => {
                    return elem.vehicle.plate !== this.selectedVehicle.plate;
                });
                this.$success('Successfully removed vehicle from Blacklist');
                $('#unBlacklistModal').modal('hide');
            })
            .catch(error => {
                this.$error(this.$t('blacklist.unblacklist_success'), error);
                this.removingVehicle = false;
            })
        },
        clearFilter() {
            this.$refs.form.resetForm();
            // this.loadBlacklist();
        },
        openModal(vehicle) {
            this.selectedVehicle = vehicle;
            this.unBlacklistModal.show();
        }
    }

}
</script>

<style scoped>

.modal-content {
    border-left: 20px solid var(--utility-orange);
    text-align: center;
}

.modal-title h3 {
    font-size: 30px;
}

.modal-title i {
    font-size: 37px;
    margin-bottom: 8px;
}

.modal-content p {
    font-weight: 100;
    font-size: 18px;
}

</style>